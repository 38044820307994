
/** EVOSTRAP */

__webpack_public_path__ = document.getElementById('evostrap') ? document.getElementById('evostrap').getAttribute('src').split('js/evostrap.js')[0] : '/evostrap/dist/';


// Objet de gestion async des scripts
window.evostrap = {
    load: {},
    messages: {
        notLoaded: `Les conditions de chargement du script ne sont pas valide. Pour forcer le chargement, passer le paramètre forceLoading à true`
    }
};

/** EvostrapErpl
 * @deprecated
 * alias temporaire
 */

window.evostrapErpl = window.evostrap;


// import() nécessite l'importation des polyfills promise et iterator avant la transpilation babel pour fonctionner sur les anciens navigateurs
// https://babeljs.io/docs/en/babel-plugin-syntax-dynamic-import
// import "core-js/modules/es6.promise";
// import "core-js/modules/es6.array.iterator";

// Import evostrap base
import '../../evostrap-core/src/js/index';

// Intégration de datepicker
import '../../lib/datepicker/datepicker';

// POLYFILL - PROTOTYPE - PLUGIN 
import './modules/polyfill';


// ERPL toInputList
evostrap.load.toInputList = function() {
    return new Promise((resolve, reject) => {

        if ($('.erpl_to-input-list').length) {
            import ( /* webpackChunkName: "js/erpl-to-input-list" */ './modules/erpl-to-input-list').then(val => {
                resolve(val)
            }).catch((reason) => {
                reject(reason)
            });
        } else {
            resolve(evostrap.messages.notLoaded)
        }

    })
}

// ScrollTo - JQuery
/* Faire défiler la page jusqu'à l'élément que vous spécifiez. */
jQuery.fn.scrollTo = function(elem, speed, offsetTop) {
    if (!offsetTop) {
        offsetTop = 0;
    }
    $(this).animate({
        scrollTop: $(this).scrollTop() - $(this).offset().top + $(elem).offset().top + offsetTop
    }, speed == undefined ? 1000 : speed);
    return this;
};


//========================================================================================
/*                                                                                      *
 *                                 DEFINITIONS GLOBALES                                 *
 *                                                                                      */
//========================================================================================
import "./modules/erpl"


// Merge avec objet global evostrap
window.evostrap = {...window.evostrap, ...window.erpl}



//========================================================================================
/*                                                                                      *
 *                                     COOKIE POLICY                                    *
 *                                                                                      */
//========================================================================================

evostrap.load.erplPolicyCookie = function(forceLoading) {
    return new Promise((resolve, reject) => {
        if ($('.erpl_policy-cookie').length || forceLoading) {
            import ( /* webpackChunkName: "js/erpl-policy-cookie" */ './modules/erpl-policy-cookie').then(val => {
                resolve(val)
            }).catch((reason) => {
                reject(reason)
            });
        } else {
            resolve(evostrap.messages.notLoaded)
        }
    })

}

 

//========================================================================================
/*                                                                                      *
 *                                        Divers                                        *
 *                                                                                      */
//========================================================================================


//──── AIDE A L OPTIMISATION DES STYLES SANS JS ──────────────────────────────────────────


evostrap.load.jsHelper = function() {
    $('body').attr('data-js', 'true');

}


//──── Popovers et Tooltip everywhere ────────────────────────────────────────────────────


evostrap.load.initTooltips = function() {
        $(function() {
            $('[data-toggle="popover"]').popover()
        })
        $(function() {
            $('[data-toggle="tooltip"]').tooltip()
        })

        $(function() {
            $('[data-toggle="popover-html"]').popover({
                html: true
            })
        })

    }
    //──── DATEPICKER ────────────────────────────────────────────────────────────────────────


evostrap.load.initDatepicker = function() {


    $('[data-toggle="datepicker"]').each((i, datepickerInputElement) => {

        let $triggerButton = $(datepickerInputElement).next('.input-group-append:not(.disabled)').find('.btn');

        let lang = $(datepickerInputElement).attr('data-lang') || document.querySelector('html').getAttribute('lang') || 'en'


        import(/* webpackChunkName: "js/datepicker/i18n/[request]" */ `../../lib/datepicker/i18n/${lang}`).then(languageFile => {
            console.log(languageFile);

            $(datepickerInputElement).datepicker({
                autoHide: true,
                zIndex: 1050,
                trigger: $triggerButton.length ? $triggerButton : null,
                language: lang
            });
        })



        if (!$triggerButton.length) {
            console.warn(datepickerInputElement, "Veuillez ajouter un bouton déclencheur")
        }
    })
}

//──── Session selector ──────────────────────────────────────────────────────────────────

evostrap.load.erplSessionSelector = function(forceLoading) {
    return new Promise((resolve, reject) => {
        if ($('[data-toggle="session"]').length || forceLoading) {
            import ( /* webpackChunkName: "js/erpl-session-selector" */ './modules/erpl-session-selector').then(val => {
                resolve(val)
            }).catch(reason => {
                reject(reason)
            });
        } else {
            resolve(evostrap.messages.notLoaded)
        }


    })
}


//──── Clearable ─────────────────────────────────────────────────────────────────────────

evostrap.load.initClearableInput = function() {
    $('[data-clearable="true"]').on('keyup', function() {
        var $input = $(this);

        if ($input.val().length) {
            $input.addClass('clearable');
            $input.next('.input-group-append').find('.btn-clearable').one('click', function() {
                $input.val('').trigger('keyup');
                $input[0].dispatchEvent(new CustomEvent('change'))
                $input.removeClass('clearable');
            })
        } else {
            $input.removeClass('clearable');

        }


    });

}


//──── ERPL MOVE ─────────────────────────────────────────────────────────────────────────

evostrap.load.erplMove = function(forceLoading) {
    return new Promise((resolve, reject) => {

        if ($('.erpl_move').length || forceLoading) {
            import ( /* webpackChunkName: "js/erpl-move" */ './modules/erpl-move').then(val => {
                resolve(val)
            }).catch(reason => {
                reject(reason)
            });
        } else {
            resolve(evostrap.messages.notLoaded)
        }


    })
}

//──── SMOOTH SCROLL ─────────────────────────────────────────────────────────────────────

evostrap.load.erplSmoothScroll = function(forceLoading) {
    return new Promise((resolve, reject) => {

        if ($('.erpl_smooth-scroll').length || forceLoading) {
            import ( /* webpackChunkName: "js/erpl-header" */ './modules/erpl-smooth-scroll').then(val => {
                resolve(val)
            }).catch(reason => {
                reject(reason)
            });

        } else {
            resolve(evostrap.messages.notLoaded)
        }

    })
}

//──── MEP AUTOCOMPLETE ─────────────────────────────────────────────────────────────────────

evostrap.load.erplAutocompleteMeps = function(forceLoading) {
    return new Promise((resolve, reject) => {

        if ($('.erpl_autocomplete-meps').length || forceLoading) {
            import ( /* webpackChunkName: "js/erpl-autocomplete-meps" */ './modules/erpl-autocomplete-meps').then(val => {
                resolve(val)
            }).catch(reason => {
                reject(reason)
            });

        } else {
            resolve(evostrap.messages.notLoaded)
        }

    })
}
 



//========================================================================================
/*                                                                                      *
 *                                        FOOTER                                        *
 *                                                                                      */
//========================================================================================



evostrap.load.erplFooter = function(forceLoading) {
    return new Promise((resolve, reject) => {

        if ($('footer').length || forceLoading) {
            import ( /* webpackChunkName: "js/erpl-footer" */ './modules/erpl-footer').then(val => {
                resolve(val)
            }).catch(reason => {
                reject(reason)
            });
        } else {
            resolve(evostrap.messages.notLoaded)
        }


    })
}

//========================================================================================
/*                                                                                      *
 *                                     ERPL EUROMAP                                     *
 *                                                                                      */
//========================================================================================
// window.erplAnimateEuMap;

evostrap.load.erplEuroMap = function(forceLoading) {
    return new Promise((resolve, reject) => {

        if ($('.erpl_map-europe').length || forceLoading) {
            import ( /* webpackChunkName: "js/erpl-map-europe" */ './modules/erpl-map-europe').then(val => {
                resolve(val)
            }).catch(reason => {
                reject(reason)
            });
        } else {
            resolve(evostrap.messages.notLoaded)
        }


    })
}

//========================================================================================
/*                                                                                      *
 *                                     ERPL Animate                                     *
 *                                                                                      */
//========================================================================================


evostrap.load.erplAnimate = function(forceLoading) {
    return new Promise((resolve, reject) => {

        if ($('.erpl_animate').length || forceLoading) {
            import ( /* webpackChunkName: "js/erpl-animate" */ './modules/erpl-animate').then(val => {
                resolve(val)
            }).catch(reason => {
                reject(reason)
            });

        } else {
            resolve(evostrap.messages.notLoaded)
        }

    })
}

//========================================================================================
/*                                                                                      *
 *                                   ERPL Tree Select                                   *
 *                                                                                      */
//========================================================================================


evostrap.load.erplTree = function(forceLoading) {
    return new Promise((resolve, reject) => {

        if ($('.erpl_tree').length || forceLoading) {
            import ( /* webpackChunkName: "js/erpl-tree" */ './modules/erpl-tree').then(val => {
                resolve(val)
            }).catch(reason => {
                reject(reason)
            });

        } else {
            resolve(evostrap.messages.notLoaded)
        }

    })
}

//========================================================================================
/*                                                                                      *
 *                                     ERPL DROPDOWN                                    *
 *                                                                                      */
//========================================================================================


evostrap.load.erplDropdown = function(forceLoading) {
    return new Promise((resolve, reject) => {

        if ($('.erpl_dropdown').length || forceLoading) {
            import ( /* webpackChunkName: "js/erpl-dropdown" */ './modules/erpl-dropdown').then(module => {
                resolve($.fn.erplDropdown)

                if (!forceLoading) {

                    $('.erpl_dropdown').each(function () {

                        $(this).erplDropdown();
                    })
                    
                }
            }).catch(reason => {
                reject(reason)
            });
        } else {
            resolve(evostrap.messages.notLoaded)
        }

    })
}


//========================================================================================
/*                                                                                      *
 *                                     ERPL REFINER                                     *
 *                                                                                      */
//========================================================================================


evostrap.load.erplRefiner = function(forceLoading) {
    return new Promise((resolve, reject) => {

        if ($('.erpl_refiner').length || forceLoading) {
            import ( /* webpackChunkName: "js/erpl-refiner" */ './modules/erpl-refiner').then(val => {
                resolve(val)
            }).catch(reason => {
                reject(reason)
            });

        } else {
            resolve(evostrap.messages.notLoaded)
        }

    })
}


//========================================================================================
/*                                                                                      *
 *                                  ERPL CHECKBOX LIST                                  *
 *                                                                                      */
//========================================================================================


evostrap.load.erplCheckboxList = function(forceLoading) {
    return new Promise((resolve, reject) => {

        if ($('.erpl_checkbox-list').length || forceLoading) {
            import ( /* webpackChunkName: "js/erpl-checkbox-list" */ './modules/erpl-checkbox-list').then(val => {
                resolve(val)
            }).catch(reason => {
                reject(reason)
            });

        } else {
            resolve(evostrap.messages.notLoaded)
        }


    })
}

//========================================================================================
/*                                                                                      *
 *                                    FORM VALIDATION                                   *
 *                                                                                      */
//========================================================================================
// jQuery Validation


evostrap.load.erplFormValidation = function(forceLoading) {
    return new Promise((resolve, reject) => {

        if ($('.erpl_form-validation').length || forceLoading) {
            import ( /* webpackChunkName: "js/erpl-form-validation" */ './modules/erpl-form-validation').then(val => {
                resolve(val)
            }).catch(reason => {
                reject(reason)
            });
        } else {
            resolve(evostrap.messages.notLoaded)
        }


    })
}

//========================================================================================
/*                                                                                      *
 *                                    ERPL FORM STEPS                                   *
 *                                                                                      */
//========================================================================================


evostrap.load.erplFormSteps = function(forceLoading) {
    return new Promise((resolve, reject) => {

        if ($('.erpl_form-steps').length || forceLoading) {
            import ( /* webpackChunkName: "js/erpl-form-steps" */ './modules/erpl-form-steps').then(val => {
                resolve(val)
            }).catch(reason => {
                reject(reason)
            });
        } else {
            resolve(evostrap.messages.notLoaded)
        }

    })
}

//========================================================================================
/*                                                                                      *
 *                        Erpl_full-width-banner-section parallax                       *
 *                                                                                      */
//========================================================================================

evostrap.load.erplFullWidthBannerSection = function(forceLoading) {
    return new Promise((resolve, reject) => {

        if ($('.erpl_full-width-banner-section').length || forceLoading) {
            import ( /* webpackChunkName: "js/erpl-full-width-banner-section" */ './modules/erpl-full-width-banner-section').then(val => {
                resolve(val)
            }).catch(reason => {
                reject(reason)
            });
        } else {
            resolve(evostrap.messages.notLoaded)
        }


    })
}

//========================================================================================
/*                                                                                      *
 *                                 ERPL FORM AUTOSUBMIT                                 *
 *                                                                                      */
//========================================================================================

evostrap.load.initAutoSubmit = function() {

    $('form[data-autosubmit="true"]').on('change', function() {
        $(this).trigger('submit');
    })

}

//========================================================================================
/*                                                                                      *
 *                                 ERPL TABLE RESPONSIVE                                *
 *                                                                                      */
//========================================================================================


evostrap.load.erplTableResponsive = function(forceLoading) {
        return new Promise((resolve, reject) => {

            if ($('.table.erpl_table-responsive[data-responsive-breakpoint]').length || forceLoading) {
                import ( /* webpackChunkName: "js/erpl-table-responsive" */ './modules/erpl-table-responsive').then(val => {
                    resolve(val)
                }).catch(reason => {
                    reject(reason)
                });

            } else {
                resolve(evostrap.messages.notLoaded)
            }


        })
    }
    //========================================================================================
    /*                                                                                      *
     *                                 ERPL SIDE SEARCH FORM                                *
     *                                                                                      */
    //========================================================================================
    // Déplacé vers side-search-form.js


//========================================================================================
/*                                                                                      *
 *                                    ERPL SLIDESHOW                                    *
 *                                                                                      */
//========================================================================================



evostrap.load.erplSlideshow = function(forceLoading) {
    return new Promise((resolve, reject) => {

        if ($('.erpl_slideshow').length || forceLoading) {
            import ( /* webpackChunkName: "js/erpl-slideshow" */ './modules/erpl-slideshow').then(val => {
                resolve(val)
            }).catch(reason => {
                reject(reason)
            })
        } else {
            resolve(evostrap.messages.notLoaded)
        }

    })
}

//========================================================================================
/*                                                                                      *
 *                                Scrollbar personnalisée                               *
 *                                                                                      */
//========================================================================================


evostrap.load.erplScrollbar = function(forceLoading) {
    return new Promise((resolve, reject) => {

        if ($('.erpl_scrollbar').length || forceLoading) {
            import ( /* webpackChunkName: "js/erpl-scrollbar" */ './modules/erpl-scrollbar').then(val => {
                resolve(val)
            }).catch(reason => {
                reject(reason)
            })
        } else {
            resolve(evostrap.messages.notLoaded)
        }

    })
}

//========================================================================================
/*                                                                                      *
 *                                    ERPL TAGS INPUT                                   *
 *                                                                                      */
//========================================================================================


evostrap.load.erplTagsInput = function(forceLoading) {
    return new Promise((resolve, reject) => {
        if ($('.erpl_tags-input').length || forceLoading) {
            import ( /* webpackChunkName: "js/erpl-tags-input" */ './modules/erpl-tags-input').then(val => {
                resolve(val)
            }).catch(reason => {
                reject(reason)
            })
        } else {
            resolve(evostrap.messages.notLoaded)
        }
    })

}

//========================================================================================
/*                                                                                      *
 *                                     ERPL CAPTCHA                                     *
 *                                                                                      */
//========================================================================================


evostrap.load.erplCaptcha = function(forceLoading) {
    return new Promise((resolve, reject) => {

        if ($('.erpl_captcha').length || forceLoading) {
            import ( /* webpackChunkName: "js/erpl-captcha" */ './modules/erpl-captcha').then(val => {
                resolve(val)
            }).catch(reason => {
                reject(reason)
            })
        } else {
            resolve(evostrap.messages.notLoaded)
        }


    })
}

//========================================================================================
/*                                                                                      *
 *                                       HEMICYCLE                                      *
 *                                                                                      */
//========================================================================================

evostrap.load.erplHemicycle = function(forceLoading) {
    return new Promise((resolve, reject) => {
        if ($('.erpl_hemicycle').length || forceLoading) {
            import ( /* webpackChunkName: "js/widgets/hemicycle/hemicycle" */ './../../widgets/hemicycle/js/hemicycle').then(val => {
                resolve(val)
            }).catch(reason => {
                reject(reason)
            })
        } else {
            resolve(evostrap.messages.notLoaded)
        }
    })
}

//========================================================================================
/*                                                                                      *
 *                                     SPLIT SCREEN                                     *
 *                                                                                      */
//========================================================================================

evostrap.load.erplSplitScreen = function(forceLoading) {
    return new Promise((resolve, reject) => {
        if ($('.erpl_split-screen').length || forceLoading) {
            import ( /* webpackChunkName: "js/erpl-split-screen" */ './modules/erpl-split-screen').then(val => {
                resolve(val)
            }).catch(reason => {
                reject(reason)
            })
        } else {
            resolve(evostrap.messages.notLoaded)
        }
    })
}

//========================================================================================
/*                                                                                      *
 *                                      FORM STATUS                                     *
 *                                                                                      */
//========================================================================================

evostrap.load.erplFormStatus = function(forceLoading) {
    return new Promise((resolve, reject) => {
        if ($('.erpl_form-status').length || forceLoading) {
            import ( /* webpackChunkName: "js/erpl-form-status" */ './modules/erpl-form-status').then(val => {
                resolve(val)
            }).catch(reason => {
                reject(reason)
            })
        } else {
            resolve(evostrap.messages.notLoaded)
        }
    })
}

//========================================================================================
/*                                                                                      *
 *                                POSITION STICKY RELATED                               *
 *                                                                                      */
//========================================================================================

evostrap.load.erplPositionStickyRelated = function(forceLoading) {
    return new Promise((resolve, reject) => {
        if ($('[data-position-sticky-related]').length || forceLoading) {
            import ( /* webpackChunkName: "js/erpl-position-sticky-related" */ './modules/erpl-position-sticky-related').then(val => {
                resolve(val)
            }).catch(reason => {
                reject(reason)
            })
        } else {
            resolve(evostrap.messages.notLoaded)
        }
    })
}


//========================================================================================
/*                                                                                      *
 *                                     DRAG AND DROP                                    *
 *                                                                                      */
//========================================================================================
evostrap.load.erplDragAndDrop = function(forceLoading) {
    return new Promise((resolve, reject) => {
        if (forceLoading) {
            import ( /* webpackChunkName: "js/erpl-drag-and-drop" */ './modules/erpl-drag-and-drop').then(val => {
                resolve(val)
            }).catch(reason => {
                reject(reason)
            })
        } else {
            resolve(evostrap.messages.notLoaded)
        }
    })
}

//========================================================================================
/*                                                                                      *
 *                                     NOTIFICATION                                     *
 *                                                                                      */
//========================================================================================
evostrap.load.erplToast = function(forceLoading) {
    return new Promise((resolve, reject) => {
        if (forceLoading) {
            import ( /* webpackChunkName: "js/erpl-toast" */ './modules/erpl-toast').then(val => {
                resolve(val)
            }).catch(reason => {
                reject(reason)
            })
        } else {
            resolve(evostrap.messages.notLoaded)
        }
    })
}

//========================================================================================
/*                                                                                      *
 *                                       ACCORDION                                      *
 *                                                                                      */
//========================================================================================
evostrap.load.erplAsync = function(forceLoading) {
    return new Promise((resolve, reject) => {
        if ($('[data-async-src]').length || forceLoading) {
            import ( /* webpackChunkName: "js/erpl-async-data" */ './modules/erpl-async-data').then(val => {
                resolve(val)
            }).catch(reason => {
                reject(reason)
            })
        } else {
            resolve(evostrap.messages.notLoaded)
        }
    })
}



 




// erpl Glossary

evostrap.load.erplGlossary = function(forceLoading) {
    return new Promise((resolve, reject) => {



        import ( /* webpackChunkName: "js/erpl-glossary" */ './modules/erpl-glossary').then(Module => {
            let ErplGlossary = Module.ErplGlossary;
            resolve(ErplGlossary)

            if (!forceLoading) {

                let hashedLinks = document.querySelectorAll('.erpl_glossary a[href^="#"]');
                if (hashedLinks.length) {
                    document.querySelectorAll('.erpl_glossary').forEach(glossaryElement => {
                        new ErplGlossary(glossaryElement)
                    })
                }

                
            }


        }).catch(err => reject(err))

        
    })
}

/** Merge TS modules */
const tsModules = require('../ts/dist/load');
evostrap.load = {...evostrap.load, ...tsModules.evostrap.load};
evostrap.ajax = tsModules.evostrap.ajax;
evostrap.icon = tsModules.evostrap.icon
evostrap.focusable = tsModules.evostrap.focusable

// Chargement de l'ensemble des scripts
$(function() {
    $(Object.keys(evostrap.load)).each(function() {
        evostrap.load[this]()?.catch((err) => { 
            console.warn(err);
            console.warn(this);
        });
    })
});